<template>
  <div class="organizationDetail">
    <div class="org-top">
      <div class="ort-img">
        <img src="../../assets/image/house.png" alt="" />
      </div>
      <div class="ort-message">
        <div class="om-title">{{ dataObj.companyName }}</div>
        <!-- <div class="om-text">
          <span>近一个月服务</span>
          <span style="color: #17a763">{{ 0 }}单</span>
          <span>单, 累计服务</span>
          <span style="color: #17a763">{{ 0 }}</span>
          <span>单</span>
        </div> -->
        <div class="om-text1">
          <div style="width: 450px">
            <span>主要服务品种:</span>
            <div class="omt-text">{{ dataObj.serviceVariety }}</div>
          </div>
          <div>
            <span>主要服务类型:</span>
            <div class="omt-text">{{ dataObj.serviceType }}</div>
          </div>
        </div>
        <div class="om-text1">
          <div style="width: 450px">
            <span>所在地址:</span>
            <div style="width: 340px" class="omt-text">{{ optArea() }}</div>
          </div>
          <div>
            <span>联系电话:</span>
            <div class="omt-text">{{ optPhone() }}</div>
          </div>
        </div>
        <div class="om-text1">
          <div style="width: 450px">
            <span>无人机数量:</span>
            <div style="width: 340px" class="omt-text">
              {{ dataObj.machineNum }}
            </div>
          </div>
          <!-- <div>
            <span>在线无人机:</span>
            <div class="omt-text">{{ 0 }}</div>
          </div> -->
        </div>
        <div class="om-text1">
          <span>认证：</span>
          <div class="omt-imageBox">
            <div class="omt-image">
              <img src="../../assets/image/org2.png" alt="" />
              <span>营业执照</span>
            </div>
            <!-- <div class="omt-image">
              <img src="../../assets/image/org4.png" alt="" />
              <span>农机认证</span>
            </div>
            <div class="omt-image">
              <img src="../../assets/image/org3.png" alt="" />
              <span>县农业局</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="ort-decript">
      <div class="ortd-text">{{ optWord(dataObj.workCapacityDesc) }}</div>
    </div>
  </div>
</template>
<script>
import { getOrganizationVoByid } from "@/api/serviechall";

export default {
  data() {
    return {
      dataObj: {},
    };
  },
  methods: {
    getOrganizationVoByid() {
      let query = { id: this.$route.query.id };
      getOrganizationVoByid(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          const { cmsOrganization, cmsOrganizationCapacity } = data;
          this.dataObj = { ...cmsOrganization, ...cmsOrganizationCapacity };
        }
      });
    },
    optArea() {
      const { province, city, county, town, village } = this.dataObj;
      return (
        (province || "") +
        (city || "") +
        (county || "") +
        (town || "") +
        (village || "")
      );
    },
    optPhone() {
      const phone = this.dataObj.phone + "";
      var reg = /^(\d{3})\d{4}(\d{4})$/;
      return phone.replace(reg, "$1****$2");
    },
    optWord(str) {
      if (this.isBase64(str)) {
        str = this.isInclude(escape(window.atob(str)));
        return decodeURIComponent(str);
      }
      return str;
    },
    isInclude(URL) {
      if (URL.indexOf("%") > -1) {
        return URL.replace(/%/g, "%25");
      } else {
        return URL;
      }
    },
    isBase64(str) {
      str = String(str);
      if (str === "" || str.trim() === "") {
        return false;
      }
      try {
        return btoa(atob(str)) == str;
      } catch (err) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.getOrganizationVoByid();
  },
};
</script>
<style lang="scss" scoped>
.organizationDetail {
  width: 1396px;
  margin: 0 auto;
  .org-top {
    display: flex;
    padding-top: 30px;
    .ort-img {
      width: 560px;
      height: 414px;
      overflow: hidden;
      margin-right: 30px;
      img {
        width: 550px;
        height: 400px;
      }
      img:hover {
        transform: scale(1.2);
      }
    }
    .ort-message {
      width: calc(100% - 570px);
      .om-title {
        font-size: 26px;
        color: #2b2b2b;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .om-text {
        margin-top: 30px;
        font-size: 16px;
        color: #979797;
      }
      .om-text1 {
        display: flex;
        margin-top: 30px;
        font-size: 16px;
        color: #979797;
        div {
          display: flex;
        }
        .omt-text {
          color: #2b2b2b;
          width: 250px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-left: 10px;
        }
        .omt-imageBox {
          display: flex;
          .omt-image {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-left: 40px;
            font-size: 12px;
            color: rgb(23, 167, 99);
            span {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
  .ort-decript {
    margin-top: 8px;
    height: 172px;
    overflow: hidden;
    background-color: #fff8f6;
    position: relative;
    width: 550px;
    padding: 16px;
    box-sizing: border-box;
    .ortd-text {
      width: 100%;
      height: 100%;
      word-break: break-all;
      font-size: 14px;
      color: #535353;
      text-indent: 2em;
      overflow: hidden;
    }
  }
}
</style>